import React, { useContext, useRef, useState, useEffect } from "react";
import Navbar from "../../components/navbar/Navbar";
import Sidebar from "../../components/sidebar/Sidebar";
import { AppContext } from "../../../utils/contexts/AppState";
import dayjs from "dayjs";
import io from "socket.io-client";
import "./message.css";
import { NewOrderModal } from "./NewOrder";
import ChatInput from "./ChatInput";
import { axiosInstance } from "../../components/baseUrl";
import { Protectedd } from "../../../utils/Protectedd";

const MessageCenter = () => {
  const [messages, setMessages] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [arrivalMessage, setArrivalMessage] = useState(null);
  const [buyerId, setBuyerId] = useState(null);
  const [buyersName, setBuyersName] = useState("");
  const scrollRef = useRef();
  const socket = useRef();
  const { user } = useContext(AppContext);

  const socketEvents = {
    connection: "connection",
    addUser: "add-user",
    sendMessage: "send-message",
    receiveMessage: "receive-message",
    disconnect: "disconnect",
  };

  const myContacts = async () => {
    try {
      axiosInstance.get("/admin/contacts").then((response) => {
        setContacts(response.data.data);
      });
    } catch (error) {
      console.log(error);
    }
  };

  function isMsgObject(msg) {
    let isObject;

    try {
      const orderResultObj = JSON.parse(msg);
      if (typeof orderResultObj === "object") {
        isObject = true;
      }
    } catch (error) {
      if (error) {
        isObject = false;
      }
    }

    return isObject;
  }

  useEffect(() => {
    myContacts();
  }, []);

  useEffect(() => {
    if (user) {
      socket.current = io(
        "https://buyershubapi.tradersofafrica.com:4433/api/v2"
      );
      socket.current.emit(socketEvents.addUser, user.id, user.type);
      socket.current.on(socketEvents.receiveMessage, (msg) => {
        setArrivalMessage({ fromSelf: false, message: msg });
      });
    }
  }, [user]);

  const showChats = async (buyerId, fullName) => {
    setBuyersName(fullName);
    try {
      const {
        data: { data },
      } = await axiosInstance.get(`/message/receive-message/${buyerId}`);
      setMessages(data);
      setBuyerId(buyerId);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    arrivalMessage && setMessages((prev) => [...prev, arrivalMessage]);
  }, [arrivalMessage]);

  useEffect(() => {
    if (scrollRef.current)
      scrollRef.current.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  const handleSendMsg = (msg, buyerId) => {
    try {
      const payload = {
        to: buyerId,
        from: user.id,
        message: msg,
        messageType: "MESSAGE",
        sender: user.type,
      };

      socket.current.emit(socketEvents.sendMessage, payload);
      axiosInstance.post("/message/send-message/", payload);

      const msgs = [...messages];
      msgs.push({ fromSelf: true, message: msg });
      setMessages(msgs);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <div className="dashboard-main-wrapper">
        <Navbar />
        <div className="sidebar">
          <Sidebar />
        </div>
      </div>
      <div className="row message-headers">
        <div className="col-3 message-list-header">
          <p className="text-dark">Buyer's Contacts</p>
        </div>
        <div className="col-9 message-body-header">
          <p>{buyersName}</p>
        </div>
      </div>
      <div className="row contact-body">
        <div className="col-3 message-list-body">
          {contacts &&
            contacts.map((item) => {
              return (
                <div
                  className="d-flex justify-content-between"
                  key={item.id}
                  onClick={(e) => showChats(item.id, item.fullName)}
                >
                  <div className="name-message">
                    <div>
                      <div className="buyers-name"> {item.fullName}</div>
                    </div>

                    <span>
                      {item.Message.map((msg) => {
                        return (
                          <p key={item.id} className="message-length">
                            {isMsgObject(msg.message)
                              ? "Your order has been created"
                              : msg.message}
                            {/* {msg.message} */}
                          </p>
                        );
                      })}
                    </span>
                    <hr />
                  </div>
                  {item.Message.map((msg) => {
                    return (
                      <p key={item.id} className="time">
                        {dayjs(msg.createdAt).format("hh:mm a")}
                      </p>
                    );
                  })}
                </div>
              );
            })}
        </div>

        <div className="col-9 new-message-body">
          {/* <div className="content-container"> */}
          {loading ? (
            <div className="buyers-name text-gray">
              ....Select A Buyer to Chat With
            </div>
          ) : (
            <div className=" new-message-body">
              <div className="body-two py-4">
                {/* The message body is here */}

                <div>
                  {messages.map((msg, index) => {
                    return (
                      <div
                        className={msg.fromSelf ? "mediaa-body" : "media-body"}
                        ref={scrollRef}
                        key={index}
                      >
                        <div className={msg.fromSelf ? "sender" : "receiver"}>
                          {isMsgObject(msg.message) && (
                            <p>Your order has been created</p>
                          )}
                          {!isMsgObject(msg.message) && msg.message}
                          <p className="chat-timestamp">
                            {dayjs(msg.createdAt).format("hh:mm a")}
                          </p>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>

              <div className="inputfield">
                <div className="chat-module-bottom">
                  <div align="right" className="my-3">
                    <button
                      data-bs-toggle="modal"
                      data-bs-target="#orderModal"
                      className="start-btn btn btn-dark me-2"
                      align="right"
                    >
                      Start Order
                    </button>
                  </div>
                  {/* <!-- Modal --> */}
                  <NewOrderModal
                    handleSendMsg={handleSendMsg}
                    buyerId={buyerId}
                  />

                  <ChatInput handleSendMsg={handleSendMsg} buyerId={buyerId} />
                </div>
              </div>
            </div>
          )}
          {/* </div> */}
        </div>
      </div>
    </div>
  );
};

export default Protectedd(MessageCenter);
