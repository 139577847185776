module.exports = {
    africanCountryData: {
        DZ: "Algeria",
        AO: "Angola",
        BJ: "Benin",
        BW: "Botswana",
        BF: "Burkina Faso",
        BI: "Burundi",
        CV: "Cabo Verde",
        CM: "Cameroon",
        CF: "Central African Republic (the)",
        TD: "Chad",
        KM: "Comoros (the)",
        CD: "Congo (the Democratic Republic of the)",
        CG: "Congo (the)",
        CI: "Côte d'Ivoire",
        DJ: "Djibouti",
        EG: "Egypt",
        GQ: "Equatorial Guinea",
        ER: "Eritrea",
        SZ: "Eswatini",
        ET: "Ethiopia",
        GA: "Gabon",
        GM: "Gambia (the)",
        GH: "Ghana",
        GN: "Guinea",
        GW: "Guinea-Bissau",
        KE: "Kenya",
        LS: "Lesotho",
        LR: "Liberia",
        LY: "Libya",
        MG: "Madagascar",
        MW: "Malawi",
        ML: "Mali",
        MR: "Mauritania",
        MU: "Mauritius",
        YT: "Mayotte",
        MA: "Morocco",
        MZ: "Mozambique",
        NA: "Namibia",
        NE: "Niger (the)",
        NG: "Nigeria",
        RE: "Réunion",
        RW: "Rwanda",
        SH: "Saint Helena, Ascension and Tristan da Cunha",
        ST: "Sao Tome and Principe",
        SN: "Senegal",
        SC: "Seychelles",
        SL: "Sierra Leone",
        SO: "Somalia",
        ZA: "South Africa",
        SS: "South Sudan",
        SD: "Sudan (the)",
        TZ: "Tanzania, the United Republic of",
        TG: "Togo",
        TN: "Tunisia",
        UG: "Uganda",
        EH: "Western Sahara*",
        ZM: "Zambia",
        ZW: "Zimbabwe",
      },
}