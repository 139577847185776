import React, { useState } from "react";
import logo from "../../assets/logos.png";
import "./login.css";
import { useNavigate, useParams } from "react-router-dom";
import { axiosInstance } from "../components/baseUrl";
import "react-toastify/dist/ReactToastify.css";
import { toast, ToastContainer } from "react-toastify";
import { Icon } from "react-icons-kit";
import { eyeOff } from "react-icons-kit/feather/eyeOff";
import { eye } from "react-icons-kit/feather/eye";

const SetPassword = () => {
  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useState({
    password: "",
    confirmPassword: "",
  });
  const [isSubmit, setIsSubmit] = useState(false);
  const [tokenError, setTokenError] = useState("");
  const [icon, setIcon] = useState(eyeOff);
  const [type, setType] = useState("password");
  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");

  const { userId, setPasswordToken } = useParams();

  const handlePasswordChange = (evnt) => {
    const passwordInputValue = evnt.target.value.trim();
    const passwordInputFieldName = evnt.target.name;
    const NewPasswordInput = {
      ...userInfo,
      [passwordInputFieldName]: passwordInputValue,
    };
    setUserInfo(NewPasswordInput);
  };

  const handleToggle = () => {
    if (type === "text") {
      setType("password");
      setIcon(eyeOff);
    } else {
      setType("text");
      setIcon(eye);
    }
  };

  const handleValidation = (evnt) => {
    const passwordInputValue = evnt.target.value.trim();
    const passwordInputFieldName = evnt.target.name;
    //for password
    if (passwordInputFieldName === "password") {
      const uppercaseRegExp = /(?=.*?[A-Z])/;
      const lowercaseRegExp = /(?=.*?[a-z])/;
      const digitsRegExp = /(?=.*?[0-9])/;
      const specialCharRegExp = /(?=.*?[#?!@$%^&*-])/;
      const minLengthRegExp = /.{6,}/;
      const passwordLength = passwordInputValue.length;
      const uppercasePassword = uppercaseRegExp.test(passwordInputValue);
      const lowercasePassword = lowercaseRegExp.test(passwordInputValue);
      const digitsPassword = digitsRegExp.test(passwordInputValue);
      const specialCharPassword = specialCharRegExp.test(passwordInputValue);
      const minLengthPassword = minLengthRegExp.test(passwordInputValue);
      let errMsg = "";
      if (passwordLength === 0) {
        errMsg = "Password is empty";
      } else if (!uppercasePassword) {
        errMsg = "At least one Uppercase";
      } else if (!lowercasePassword) {
        errMsg = "At least one Lowercase";
      } else if (!digitsPassword) {
        errMsg = "At least one digit";
      } else if (!specialCharPassword) {
        errMsg = "At least one Special Characters";
      } else if (!minLengthPassword) {
        errMsg = "At least minumum 6 characters";
      } else {
        errMsg = "";
      }
      setPasswordError(errMsg);
    }
    // for confirm password
    if (
      passwordInputFieldName === "confirmPassword" ||
      (passwordInputFieldName === "password" &&
        userInfo.confirmPassword.length > 0)
    ) {
      if (userInfo.confirmPassword !== userInfo.password) {
        setConfirmPasswordError("Confirm password is not match");
      } else {
        setConfirmPasswordError("");
      }
    }
  };

  const handleSubmit = async (e) => {
    setIsSubmit(true);
    e.preventDefault();
    if (userInfo.confirmPassword !== userInfo.password) {
      setConfirmPasswordError("Confirm password is not a match");
    }
    try {
      const result = await axiosInstance.post("/auth/set-employee-password", {
        password: userInfo.password,
        token: setPasswordToken,
        employeeID: userId,
      });
      localStorage.setItem("userID", result.data.data.id);
      if (result.status === 201) {
        toast.success("PASSWORD HAS BEEN SET FOR THIS USER", {
          position: "top-right",
          autoClose: 2000,
          pauseHover: true,
          draggable: true,
        });
        if (result.status === 201) {
          setTimeout(() => {
            navigate("/securityquestion");
          }, 3000);
        }
      }
    } catch (err) {
      setIsSubmit(false);
      if (err.response.status === 400) {
        setTokenError("Invalid token");
        console.log("error message", err.response);
      }

      if (err.response.status === 400) {
        toast.error("FAILED TRY AGAIN", {
          position: "top-right",
          autoClose: 4000,
          pauseHover: true,
          draggable: true,
        });
      }
    }
  };
  return (
    <div>
      <section>
        <ToastContainer />
        <div className="splash-container">
          <div className="bg-success text-light mx-auto my-4 px-5"></div>
          <div className="card ">
            <div className="card-header text-center">
              <div>
                <img
                  width="100px"
                  className="logo-img mb-3"
                  src={logo}
                  alt="logo"
                />
              </div>
              <span className="splash-description">
                Set New Password to Login
              </span>
            </div>
            <div className="card-body">
              <form onSubmit={handleSubmit}>
                <div className="input-field form-group">
                  <input
                    className="form-control form-control-lg"
                    type={type}
                    name="password"
                    placeholder="password"
                    onChange={handlePasswordChange}
                    onKeyUp={handleValidation}
                  />
                  <span>
                    <Icon onClick={handleToggle} icon={icon} size={15} />{" "}
                  </span>
                </div>
                <p className="error-message">{passwordError}</p>

                <div className="input-field form-group">
                  <input
                    className="form-control form-control-lg"
                    id="password"
                    type={type}
                    name="confirmPassword"
                    placeholder="Confirm Password"
                    onChange={handlePasswordChange}
                    onKeyUp={handleValidation}
                  />
                  <span>
                    <Icon onClick={handleToggle} icon={icon} size={15} />{" "}
                  </span>
                </div>
                <p className="error-message">{confirmPasswordError}</p>
                <p className="text-danger">{tokenError}</p>

                {isSubmit ? (
                  <button type="submit" className="btn btn-danger px-4">
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  </button>
                ) : (
                  <button type="submit" className="btn btn-dark">
                    Set Password
                  </button>
                )}
              </form>
            </div>
            <div className="card-footer bg-white p-0  ">
              <div className="card-footer-item card-footer-item-bordered">
                <a href="link" className="footer-link">
                  Forgot Password
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default SetPassword;
