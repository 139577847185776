import React, { useEffect, useState } from "react";
import { axiosInstance } from "../../components/baseUrl";
import Navbar from "../../components/navbar/Navbar";
import Sidebar from "../../components/sidebar/Sidebar";
import "./products.css";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import "jquery/dist/jquery.min.js";
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";
import { Link } from "react-router-dom";
import { Protectedd } from "../../../utils/Protectedd";

const Products = () => {
  const [product, setProduct] = useState([]);
  const [viewProduct, setViewProduct] = useState([]);
  const [loading, setLoading] = useState(false);
  const [viewLoader, setViewLoader] = useState(false);
  const [entries, setEntries] = useState([]);
  // const newToken = localStorage.getItem("tokenValue");

  // const config = {
  //   headers: {
  //     "Content-Type": "application/json",
  //     Authorization: `Bearer ${newToken}`,
  //   },
  // };

  const getData = async () => {
    try {
      axiosInstance
        .get(`/product`, {
          withCredential: true,
        })
        .then((response) => {
          setProduct(response.data.data);
          setLoading(true);
        });
    } catch (error) {
      console.log(error.response.data.erros);
    }
  };

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  useEffect(() => {
    getData();
  }, []);

  const submit = (productID) => {
    confirmAlert({
      title: "Confirm Delete",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: (e) => handleDelete(productID),
        },
        {
          label: "No",
          //onClick: () => alert('Click No')
        },
      ],
    });
  };

  const handleDelete = (productID) => {
    axiosInstance
      .delete(`${process.env.REACT_APP_BACKEND_URL}/product/${productID}`)
      .then((response) => {
        setViewProduct(response.data.data);
      });
  };

  const showDetails = (productID) => {
    setViewLoader(true);
    axiosInstance
      .get(`${process.env.REACT_APP_BACKEND_URL}/product/${productID}`)
      .then((response) => {
        setViewProduct(response.data.data);
        const arr = Object.entries(response.data.data.productSpecification);
        setEntries(arr);
        setViewLoader(false);
      });
  };

  useEffect(() => {
    //initialize datatable
    $(document).ready(function() {
      setTimeout(function() {
        $("#example").DataTable();
      }, 2000);
    });
  }, []);
  if (!loading) {
    return (
      <div
        className="spinner mx-auto"
        align="center"
        id="spinner"
        style={{
          position: "absolute",
          top: "calc(50% - 60px)",
          left: "calc(50% - 60px)",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          margin: "auto",
        }}
      ></div>
    );
  }

  return (
    <>
      {/* <!-- main wrapper --> */}
      <div className="dashboard-main-wrapper">
        <Navbar />
        <Sidebar />
        {/* <!-- wrapper  --> */}
        <div className="dashboard-wrapper">
          <div className="container-fluid dashboard-content">
            {/* <!-- pageheader --> */}
            <div className="d-flex justify-content-between">
              <div>
                <h2 className="pageheader-title">Product Overview</h2>
              </div>

              <div>
                <Link to="/createproduct">
                  <button className="btn btn-dark py-1">Create Products</button>
                </Link>
              </div>
            </div>
            {/* <!-- end pageheader --> */}

            <div className="row">
              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <div className="card">
                  <div className="card-header" style={{ textAlign: "left" }}>
                    <h5 className="mb-0 font-bold">All Products</h5>

                    {/* <p>This example shows DataTables and the Buttons extension being used with the Bootstrap 4 framework providing the styling.</p> */}
                  </div>
                  <div className="card-body">
                    <div className="table-responsive">
                      <div
                        id="example wrapper"
                        className="dataTables_wrapper dt_bootstrap4"
                      ></div>
                      <div className="container">
                        <table
                          id="example"
                          className="table table-hover table-bordered"
                          style={{ width: "100%", textAlign: "left" }}
                        >
                          <thead>
                            <tr>
                              {/* <th>ID</th> */}
                              <th>Product ID</th>

                              <th>Product Name</th>
                              <th>Min Price Per Unit</th>
                              <th>maxPricePerUnit</th>

                              <th>supplyCapacity</th>

                              <th>minDuration</th>
                              <th>maxDuration</th>

                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {product.map((item, index) => {
                              return (
                                <tr key={item.id}>
                                  {/* <td>{item.id}</td> */}
                                  <td>{index + 1}</td>
                                  <td>{item.productName}</td>
                                  <td>
                                    ${numberWithCommas(item.minPricePerUnit)}
                                  </td>
                                  <td>
                                    ${numberWithCommas(item.maxPricePerUnit)}
                                  </td>

                                  <td>
                                    {numberWithCommas(item.supplyCapacity)}
                                  </td>
                                  <td>{item.minDuration}</td>
                                  <td>{item.maxDuration}</td>

                                  <td className="action-table">
                                    <div className="nav-item dropdown">
                                      <Link
                                        className="nav-link main-nav-link position-absolute"
                                        align="right"
                                        to="#"
                                        id="navbarDropdown"
                                        role="button"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                        style={{
                                          right: "-15px",
                                          top: "-10px",
                                          color: "black",
                                        }}
                                      >
                                        <i
                                          className="fa fa-chevron-down"
                                          align="right"
                                          aria-hidden="true"
                                        ></i>
                                      </Link>
                                      <ul
                                        className="dropdown-menu animate slideIn"
                                        aria-labelledby="navbarDropdown"
                                        style={{ width: "100px !important" }}
                                      >
                                        <li>
                                          <div
                                            className="dropdown-item"
                                            onClick={(e) =>
                                              showDetails(item.id)
                                            }
                                            data-bs-toggle="modal"
                                            data-bs-target="#exampleModal"
                                          >
                                            View
                                          </div>
                                        </li>
                                        <li>
                                          <Link to={`/editproduct/${item.id}`}>
                                            <div className="dropdown-item">
                                              Edit
                                            </div>
                                          </Link>
                                        </li>
                                        <li>
                                          <div
                                            className="dropdown-item text-danger"
                                            onClick={(e) => submit(item.id)}
                                          >
                                            Delete
                                          </div>
                                        </li>
                                      </ul>
                                    </div>

                                    <div
                                      className="modal fade"
                                      id="exampleModal"
                                      tabIndex="-1"
                                      aria-labelledby="exampleModalLabel"
                                      aria-hidden="true"
                                    >
                                      {viewLoader ? (
                                        <div
                                          className="spinner mx-auto"
                                          align="center"
                                          id="spinner"
                                          style={{
                                            position: "absolute",
                                            top: "calc(50% - 60px)",
                                            left: "calc(50% - 60px)",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            textAlign: "center",
                                            margin: "auto",
                                          }}
                                        ></div>
                                      ) : (
                                        <div className="modal-dialog modal-xl">
                                          <div className="modal-content">
                                            <div className="modal-header">
                                              <h3
                                                className="modal-title"
                                                id="exampleModalLabel"
                                              >
                                                Product Details Management
                                              </h3>
                                              <button
                                                type="button"
                                                className="btn-close"
                                                data-bs-dismiss="modal"
                                                aria-label="Close"
                                              ></button>
                                            </div>
                                            <div className="modal-body">
                                              <div className="row">
                                                <div className="col-12 col-lg-6">
                                                  <h6 className="top-product-heading">
                                                    Featured Product Image
                                                  </h6>
                                                  <div className="product-img-ctn">
                                                    <div
                                                      className="product-img-ctn-featured"
                                                      style={{
                                                        border:
                                                          "1px solid #dddddd",
                                                      }}
                                                    >
                                                      <img
                                                        className="product-image"
                                                        src={
                                                          viewProduct.productImages &&
                                                          viewProduct.productImages.filter(
                                                            (image) =>
                                                              image.isMain ===
                                                              true
                                                          )[0].image
                                                        }
                                                        alt="product"
                                                      />
                                                    </div>
                                                    <div>
                                                      {viewProduct.productImages &&
                                                        viewProduct.productImages
                                                          .filter(
                                                            (image) =>
                                                              image.isMain ===
                                                              false
                                                          )
                                                          .map(
                                                            (image, index) => (
                                                              <img
                                                                key={index}
                                                                src={
                                                                  image.image
                                                                }
                                                                alt=""
                                                                className="other-image"
                                                              />
                                                            )
                                                          )}
                                                    </div>
                                                  </div>

                                                  <Link
                                                    to={`/editproduct/${item.id}`}
                                                  >
                                                    <h5 className="edit-image">
                                                      Update images
                                                    </h5>
                                                  </Link>
                                                </div>
                                                <div className="col-12 col-lg-6">
                                                  <h6 className="top-product-heading">
                                                    Product Details
                                                  </h6>
                                                  <div className="product-details">
                                                    <div className="mx-auto text-center">
                                                      <div className="row">
                                                        <div className="col-12 col-lg-6 d-flex">
                                                          <p className="px-2">
                                                            Product Name:
                                                          </p>
                                                          <p>
                                                            {
                                                              viewProduct.productName
                                                            }
                                                          </p>
                                                        </div>
                                                        <div className="col-12 col-lg-6 d-flex">
                                                          <p className="px-2">
                                                            Supply Capacity:
                                                          </p>
                                                          <p>
                                                            {
                                                              viewProduct.supplyCapacity
                                                            }
                                                          </p>
                                                        </div>
                                                      </div>
                                                      <div className="row">
                                                        <div className="col-12 col-lg-6 d-flex">
                                                          <p className="px-2">
                                                            Subcategory:
                                                          </p>
                                                          <p>
                                                            {
                                                              viewProduct.subCategory
                                                            }
                                                          </p>
                                                        </div>
                                                        <div className="col-12 col-lg-6 d-flex">
                                                          <p className="px-2">
                                                            Category:
                                                          </p>
                                                          <p>
                                                            {
                                                              viewProduct.parentCategory
                                                            }
                                                          </p>
                                                        </div>
                                                      </div>
                                                      <div className="row">
                                                        <div className="col-12 col-lg-6 d-flex">
                                                          <p className="px-2">
                                                            Max Lead Time:
                                                          </p>
                                                          <p>
                                                            {
                                                              viewProduct.minDuration
                                                            }
                                                          </p>
                                                        </div>
                                                        <div className="col-12 col-lg-6 d-flex">
                                                          <p className="px-2">
                                                            Min Duration:
                                                          </p>
                                                          <p>
                                                            {
                                                              viewProduct.minDuration
                                                            }
                                                          </p>
                                                        </div>
                                                      </div>
                                                      <div className="row">
                                                        <div className="col-12 col-lg-6 d-flex">
                                                          <p className="px-2">
                                                            Unit:
                                                          </p>
                                                          <p>
                                                            {
                                                              viewProduct.unitForMinOrder
                                                            }
                                                          </p>
                                                        </div>
                                                        <div className="col-12 col-lg-6 d-flex">
                                                          <p className="px-2">
                                                            Min Price:
                                                          </p>
                                                          <p>
                                                            USD
                                                            {
                                                              viewProduct.minPricePerUnit
                                                            }
                                                          </p>
                                                        </div>
                                                      </div>
                                                    </div>
                                                    {/* PRODUCT SPECIFICATION */}
                                                    <div>
                                                      <div>
                                                        <p className="product-heading">
                                                          Product Specification:
                                                        </p>
                                                      </div>
                                                      <div className="modal-bodyy specification-content">
                                                        <table
                                                          className="table-striped"
                                                          width="100%"
                                                        >
                                                          <tbody>
                                                            {entries &&
                                                              entries.map(
                                                                (entry, id) => {
                                                                  return (
                                                                    <tr
                                                                      className="product-specification-style"
                                                                      key={id}
                                                                    >
                                                                      <td className="p">
                                                                        {
                                                                          entry[0]
                                                                        }
                                                                      </td>

                                                                      <td className="p">
                                                                        {
                                                                          entry[1]
                                                                        }
                                                                      </td>
                                                                    </tr>
                                                                  );
                                                                }
                                                              )}
                                                          </tbody>
                                                        </table>
                                                      </div>
                                                    </div>
                                                    <h6 className="modal-bodyy">
                                                      {viewProduct.CountryTraded &&
                                                        viewProduct.CountryTraded.map(
                                                          (productInfo) => {
                                                            return (
                                                              <div
                                                                className="d-flex"
                                                                key={
                                                                  productInfo.id
                                                                }
                                                              >
                                                                <div className="d-flex px-2">
                                                                  <p>
                                                                    Country
                                                                    Traded
                                                                  </p>{" "}
                                                                  :
                                                                  <p className="px-2">
                                                                    {
                                                                      productInfo.countryName
                                                                    }
                                                                  </p>
                                                                </div>
                                                                <div className="d-flex">
                                                                  <p>Price:</p>
                                                                  <p className="px-2">
                                                                    USD,{" "}
                                                                    {
                                                                      productInfo.price
                                                                    }
                                                                  </p>
                                                                </div>
                                                              </div>
                                                            );
                                                          }
                                                        )}
                                                    </h6>
                                                    {/* MINIMUM AND MAXIMUM PRICE */}
                                                    <div className="product-info">
                                                      <div className="modal-bodyy">
                                                        <p className="px-1">
                                                          {" "}
                                                          Maximum Price Per
                                                          Unit:
                                                        </p>
                                                        <p className="px-1">
                                                          USD{" "}
                                                          {
                                                            viewProduct.maxPricePerUnit
                                                          }
                                                        </p>
                                                      </div>
                                                      <div className="modal-bodyy">
                                                        <p className="px-1">
                                                          Minimum Price Per
                                                          Unit:
                                                        </p>
                                                        <p className="px-1">
                                                          USD
                                                          {
                                                            viewProduct.minPricePerUnit
                                                          }
                                                        </p>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <Link
                                                    to={`/editproduct/${viewProduct.id}`}
                                                  >
                                                    <h5 className="edit-image">
                                                      Update product details
                                                    </h5>
                                                  </Link>
                                                </div>
                                              </div>
                                              <div className="my-2">
                                                Product Information
                                              </div>
                                              <div className="detailz">
                                                <div className="row">
                                                  <div className="col-12 detailz-body">
                                                    {
                                                      viewProduct.productDescription
                                                    }
                                                  </div>
                                                </div>
                                              </div>
                                              <Link
                                                to={`/editproduct/${viewProduct.id}`}
                                              >
                                                <h5
                                                  style={{ color: "#DC4D04" }}
                                                >
                                                  Update product information
                                                </h5>
                                              </Link>
                                            </div>
                                            <div className="modal-footer">
                                              <button
                                                type="button"
                                                className="btn btn-secondary px-4"
                                                data-bs-dismiss="modal"
                                              >
                                                Close
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- end main wrapper --> */}
      </div>
    </>
  );
};

export default Protectedd(Products, ["SUPER_ADMIN", "SOURCE_PRO_ADMIN"]);
